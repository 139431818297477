import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import mapImage from 'common/assets/image/charity/map-alt.png';

export const NameWrapper = styled.div`
    clear: both;
    overflow: hidden;
    margin-bottom: 1.3em;
    
    .item-left {
      width: 49%;
      float: left;
    }
    
    .item-right {
      width: 49%;
      float: right;
    }
`;

const SectionWrapper = styled.div`
  width: 100%;
  padding: 2em 0 2em;
  position: relative;
  overflow: hidden;
  
  .blog__image_container {
    float:right;
    padding: 0 0 2em 2em;
    max-width: 500px;
  }
  
  .blog__text {
    font-size: 1.1em;
    line-height: 1.7em;
    
    li {
      list-style: disc;
      font-size: 0.9em;
      margin-left: 2em;
      padding-bottom: 1em;
    }
    
    p:first-of-type {
      font-size: 1.2em;
    }
  }
  
  .post-meta-single {
    color: #345e3b!important;
  
    .tag-base {
      height: 28px;
      line-height: 28px;
      padding: 0 14px;
      display: inline-block;
      border-radius: 3px;
      font-size: 15px;
      color: #fff;
      float:left;
    }
  
    .date {
        font-weight: bold;
        padding-left: 1em;
        float: left;
        padding-top: 0.1em;
    }
  }

  
  .demo-bg {
      opacity: 0.08;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0,-65%);
      width: 100%;
      height: auto;
  }
  
  @media only screen and (max-width: 1440px) {
    margin: 70px 0;
    padding: 120px 0 90px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1360px) {
    margin: 0;
    padding: 2em 0 70px;
    .row {
      > .col {
        &:first-child {
          width: 53%;
        }
        &:last-child {
          width: 47%;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    margin: 0;
    padding: 0 0 50px;
    
    .blog__image_container  {
      padding: 0;
      max-width: 100%;
      width: 100%;
      margin-bottom: 2em;
    }
  }
  @media only screen and (max-width: 667px) {
    .row {
      > .col {
        width: 100%;
      }
    }
  }
`;

export default SectionWrapper;
