import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'common/components/UI/Container';
import SectionWrapper from './donateSection.style';
import { Link } from 'gatsby';

import moment from 'moment';
import NewsFeed from "../../NewsFeed";

const DonateSection = ({ data }) => {
  return (
    <SectionWrapper id="support-us-section">
      <Container width="1260px">
        <div className="single-blog-details">
          {
            data.datoCmsNews.mainImage && (
              <div className="blog__image_container ">
                <img src={ data.datoCmsNews.mainImage.url } />
                <p className="image__title">{ data.datoCmsNews.mainImage.title }</p>
              </div>
            )
          }
          <div className="blog__text" dangerouslySetInnerHTML={{__html: data.datoCmsNews.openingText}} />

        </div>

        <NewsFeed items={data.allDatoCmsNews.nodes} exclude={data.datoCmsNews.slug} />
      </Container>
    </SectionWrapper>
  );
};

// DonateSection style props
DonateSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textStyle: PropTypes.object,
};

// DonateSection default style
DonateSection.defaultProps = {
  // DonateSection row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // DonateSection col default style
  col: {
    pl: '15px',
    pr: '15px',
    mb: '30px',
    color: '#fff',
  },
};

export default DonateSection;
