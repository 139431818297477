import React, { Fragment } from 'react';
import {graphql} from "gatsby";

import Item from './item';
import {ThemeProvider} from "styled-components";
import Sticky from "react-stickynode";
import CookieNotice from "../CookieNotice";
import { charityTheme } from 'common/theme/charity';
import Footer from 'components/Footer';
import Navbar from 'components/NavbarNews';
import {
    GlobalStyle,
    CharityWrapper,
} from 'containers/Charity/charity.style';
import { ResetCSS } from 'common/assets/css/style';
import Seo from 'components/seo';
import TitleBlock from './title-block';
import ComponentRenderer from "../ComponentRenderer";

const BlogDetailsPage = ({ data }) => {

    console.log(data.datoCmsNews.seo);

    return (
      <ThemeProvider theme={charityTheme}>
          <Fragment>
              <Seo title={data.datoCmsNews.seo.title} description={data.datoCmsNews.seo.description} image={data.datoCmsNews.seo.image.url}  />
              <ResetCSS />
              <GlobalStyle />
              {/* Start charity wrapper section */}
              <CharityWrapper>
                  <Navbar />
                  <TitleBlock data={data} />
                  <Item data={data} />
                  <ComponentRenderer data={data.datoCmsNews} />
                  <Footer />
                  <CookieNotice />
              </CharityWrapper>
              {/* End of charity wrapper section */}
          </Fragment>
      </ThemeProvider>
    );
}

export default BlogDetailsPage

export const query = graphql`
query GetNewsItem($slug: String!) {
  datoCmsNews(slug: {eq: $slug}) {
    slug
    title
    category {
      color {
        hex
      }
      name
    }
    meta {
      createdAt
    }
    mainImage {
      url
      title
    }
    seo {
      description
      image {
        url
      }
      title
    }
    openingText
    components {
      ... on DatoCmsHeaderBlock {
        id
        model {
          name
        }
        title
        text
        hasButton
        buttonText
        buttonUl
        image {
          url
        }
      }
      ... on DatoCmsBecomeACouncillorForm {
        id
        title
        subtext
        model {
          name
        }
      }
      ... on DatoCmsPictoList {
        id
        model {
          name
        }
        items {
          icon {
            url
          }
          text
          title
        }
        subtitle
        title
        bottomText
      }
      ... on DatoCmsDocumentsBlock {
        id
        title
        subtext
        model {
          name
        }
        items {
          title
          description
          document {
            url
          }
        }
      }
      ... on DatoCmsTextBlock {
        id
        model {
          name
        }
        text
      }
      ... on DatoCmsEsu {
        id
        model {
          name
        }
        title
        description
      }
      ... on DatoCmsBackgroundSection {
        id
        model {
          name
        }
        backgroundImage {
          url
        }
      }
    }
  }
  allDatoCmsNews(
      sort: {fields: meta___createdAt, order: DESC}, 
      limit: 6,
      filter: {visible: {eq: true}}
  ) {
    nodes {
      title
      slug
      category {
        color {
          hex
        }
        name
      }
      meta {
        createdAt
      }
      mainImage {
        url
      }
    }
  }
}
`;

